import styled, { css } from 'styled-components';

import { linkNoDecoration, textOverflow, hoverAnimation } from '@vectorworks/vcs-ui/dist/lib/styles/common';
import { respondTo } from '@vectorworks/vcs-ui/dist/lib/styles/responsive';

const SIDEBAR_WIDTH = '225px';
const TOOLBAR_LG_HEIGHT = '73px';
const TOOLBAR_XS_HEIGHT = '56px';

export const StyledSidebar = styled.div(({ open }) => `
    ${respondTo('phone', `
        display: ${open ? 'flex' : 'none'};
        top: ${TOOLBAR_XS_HEIGHT};
    `)};

    ${respondTo('tablet', `
        display: ${open ? 'flex' : 'none'};
    `)};

    width: ${SIDEBAR_WIDTH};

    position: fixed;
    top: ${TOOLBAR_LG_HEIGHT};
    left: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    
    overflow-y: auto;
    overflow-x: hidden;

    border-right: 1px solid var(--border-color);
    background-color: var(--bg-color);
`);

export const Overlay = styled.div`
    ${respondTo('phone', `
        display: block;    
        top: ${TOOLBAR_XS_HEIGHT};
    `)};

    ${respondTo('tablet', css`
        display: block;    
    `)};

    display: none;

    position: fixed;
    top: ${TOOLBAR_LG_HEIGHT};
    left: ${SIDEBAR_WIDTH};
    right: 0;
    bottom: 0;
    background-color: rgba(var(--black-rgb), 0.1);
`;

export const svgColors = css`
    #google_drive {
        .st0{fill:#FFCF4A;}
        .st1{fill:#1CA261;}
        .st2{fill:#4687F4;}
    }

    #dropbox {
        .st0{fill:#0061FF;}
    }

    #one_drive {
        .st0{fill:#0364B8;}
        .st1{fill:#0078D4;}
        .st2{fill:#1490DF;}
        .st3{fill:#28A8EA;}
    }
`;

export const StyledItem = styled.a`
    ${textOverflow}
    ${linkNoDecoration()}
    ${hoverAnimation()}
    ${svgColors}

    height: 48px;

    flex-shrink: 0;

    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;

    ${({ isSelected }) => isSelected && css`
        background-color: var(--vcs-color--primary);
        color: white !important;

        &:hover {
            color: white !important;
        }
    `}
`;

export const ItemTitle = styled.div`
    ${textOverflow}
    flex: 1;
    min-width: 0;
`;

export const ItemIcon = styled.div`
    margin: 8px;
    margin-left: 12px;

    i {
        vertical-align: top;
    }

    svg {
        flex-shrink: 0;
        fill: var(--text-primary-color);
        width: var(--vcs-icon-size-md);
        height: var(--vcs-icon-size-md);
        display: block;
        
        path, polygon {
            transition: fill 0.4s ease;
        }
    }
`;

export const IntegrationArrow = styled.div`
    align-self: baseline;
    margin: 0 10px;
`;
