import React from 'react';
import ReactDOM18 from '../../../utils/ReactMigrator';

import { EmailTags } from '@vectorworks/vcs-ui/dist/lib/Input/Tags/EmailTags';
import { ActionGroup } from '@vectorworks/vcs-ui/dist/lib/Toolbar';

import CopyJob from '../../../job/copy/CopyJob';
import { Sidebar } from '../../../Sidebar/Main/Sidebar';
import TrashCategorySelect from '../../../Components/TrashCategorySelect';

const COMPONENTS = {
    CopyJob,
    EmailTags,
    Sidebar,
    TrashCategorySelect,
    ActionGroup
};

ko.bindingHandlers.react = {
    init () {
        return { controlsDescendantBindings: true };
    },
    update (element, valueAccessor) {
        const { component, props } = valueAccessor();
        ReactDOM18.render(React.createElement(COMPONENTS[component], props), element, { ignoreRootWarning: true });
    }
};
