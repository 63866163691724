import React from 'react';

import { observer } from 'mobx-react';
import styled from 'styled-components';

import { formatBytes } from '../../lib/file';
import storageQuotaStore, { bytesToMB } from './StorageQuotaStore';

import { SidebarItem } from '../Main/Sidebar';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: auto;
    margin-bottom: 15px;
`;

const QuotaWrapper = styled.div`
    width: 100%;
    padding: 0 15px;
    flex-shrink: 0;
`;

const Quota = styled.div`
    position: relative;
    height: 4px;
    border-radius: 4px;
    background-color: var(--bg-color-dark);

    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 4px;
        border-radius: 4px;
        background-color: ${({ percentage }) => percentage < 90
        ? 'var(--grey8)'
        : 'var(--vcs-color--light-red)'};
        width: ${({ percentage }) => (percentage > 100 ? 100 : percentage) + '%'};
        max-width: 100%;
    }
`;

const Text = styled.div`
    margin-top: 4px;
    line-height: 43px;
    color: var(--grey8);
`;

function Storage () {
    const usageMB = bytesToMB(storageQuotaStore.storageUsed);
    const currentUsage = usageMB < 1024
        ? usageMB.toFixed(2) + 'MB'
        : (usageMB / 1024).toFixed(2) + 'GB';

    return (
        <Wrapper>
            <SidebarItem
                title={gettext('Storage')}
                icon='storage'
                isSelected={ false }
                style={{ alignSelf: 'start', marginTop: 20, cursor: 'unset' }}
            />

            <QuotaWrapper>
                <Quota percentage={(bytesToMB(storageQuotaStore.storageUsed) / storageQuotaStore.storageQuota) * 100} />
            </QuotaWrapper>
            <Text>
                {
                    interpolate(
                        gettext('%(currentStorage)s of %(storageQuota)s used'),
                        {
                            currentStorage: currentUsage,
                            storageQuota: formatBytes(storageQuotaStore.storageQuota * 1024 * 1024)
                        },
                        true
                    )
                }
            </Text>
        </Wrapper>
    );
}

export default observer(Storage);
