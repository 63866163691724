import { browser } from '../lib';
import fullScreen from './fullscreen';

Settings.device = {};
Settings.device.isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
Settings.device.isIPad = /iPad/.test(navigator.userAgent) && !window.MSStream;
Settings.device.isIPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
Settings.device.isFullscreen = fullScreen.isFullscreen;
Settings.device.fullScreenEnabled = fullScreen.enabled;
Settings.device.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || 'ontouchstart' in window;
Settings.device.browser = browser.is.IE11()
    ? 'IE11'
    : browser.is.Edge()
        ? 'Edge'
        : browser.is.Safari()
            ? 'Safari'
            : browser.is.Opera()
                ? 'Opera'
                : '';

Settings.device.xsMax = 767;
Settings.device.smMin = Settings.device.xsMax + 1;
Settings.device.smMax = 991;
Settings.device.mdMin = Settings.device.smMax + 1;
Settings.device.mdMax = 1199;
Settings.device.lgMin = 1200;

Settings.device.getScreenInfo = function () {
    let size = '';
    switch (true) {
    case (window.innerWidth <= Settings.device.xsMax):
        size = 'xs';
        break;
    case (window.innerWidth <= Settings.device.smMax):
        size = 'sm';
        break;
    case (window.innerWidth <= Settings.device.mdMax):
        size = 'md';
        break;
    default:
        size = 'lg';
    }

    return {
        size,
        device: Settings.device.isMobile
            ? size + '-mobile'
            : size + '-desktop'
    };
};
